import PropTypes from 'prop-types';
import React from 'react';

import { formatDateTime } from '../../lib/fmt';

export default class MJournalEntry extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    entry: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { entry: props.entry };
  }

  render() {
    return (
      <div className="MJournalEntry modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
        </div>
      </div>
    );
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    const { entry } = this.state;
    return (
      <div className="modal-header">
        <h5 className="modal-title">
          {entry.action} {entry.resource}
        </h5>
        <button type="button" className="close" onClick={close}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { entry } = this.state;
    return (
      <div className="modal-body">
        <div className="row mb-3">
          <div className="col">{entry.worker_name}</div>
          <div className="col text-right">{formatDateTime(entry.created_at)}</div>
        </div>
        <div className="">
          <pre>{JSON.stringify(entry.body, null, 2)}</pre>
        </div>
      </div>
    );
  }
}
