import dateFns from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { formatDate, formatTime } from '../../lib/fmt';
import { getBaseWorkerOptions } from '../../services/worker.service';
import List from '../List';
import MJournalEntry from '../modals/MJournalEntry';

export default class PJournal extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const today = dateFns.format(new Date(), 'YYYY-MM-DD');
    this.state = {
      isLoading: true,
      appliedQuery: {},
      currentQuery: {
        date1: today,
        date2: today,
        workerId: '',
        resource: '',
      },
      workers: [],
      items: [],
      page: 1,
    };
  }

  render() {
    return (
      <div className="PJournal">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage(null, 'Журнал');
    try {
      const { items: workers } = await app.getApi().get('/workers');
      this.setState({ isLoading: false, workers });
    } catch (err) {
      app.onError(err);
    }
    await this.refreshData();
    this.timer = setInterval(() => {
      const { currentQuery, appliedQuery } = this.state;
      for (const key in appliedQuery) {
        if (currentQuery[key] !== appliedQuery[key]) {
          this.refreshData();
          return;
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  // event handlers

  onFilterChange(e, name) {
    if (['date1', 'date2'].includes(name) && !e.target.value) {
      return;
    }
    this.setState({
      currentQuery: { ...this.state.currentQuery, [name]: e.target.value },
      page: 1,
    });
  }

  async onItemSelect(entry) {
    const { app } = this.props;
    await app.showModal(MJournalEntry, { entry });
  }

  onPageChange(page) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { date1, date2, workerId, resource } = this.state.currentQuery;
    return (
      <div className="d-flex mb-3">
        <input
          className="form-control w-160px"
          type="date"
          value={date1}
          onChange={(e) => this.onFilterChange(e, 'date1')}
        />
        <input
          className="form-control ml-3 w-160px"
          type="date"
          value={date2}
          onChange={(e) => this.onFilterChange(e, 'date2')}
        />
        <select
          className="form-control ml-3  w-300px"
          value={workerId}
          onChange={(e) => this.onFilterChange(e, 'workerId')}
        >
          {this.renderWorkerSelectOptions()}
        </select>
        <input
          className="form-control ml-3"
          type="text"
          value={resource}
          placeholder="Поиск по ресурсу"
          onChange={(e) => this.onFilterChange(e, 'resource')}
        />
      </div>
    );
  }

  renderWorkerSelectOptions() {
    const { workers } = this.state;
    const options = getBaseWorkerOptions(workers);
    return [
      ...[
        <option value="" key="all">
          Все сотрудники
        </option>,
        <option value="" key="dash" disabled>
          —
        </option>,
      ],
      options.map((option) => (
        <option key={option.value} value={option.value} disabled={option.disabled}>
          {option.title}
        </option>
      )),
    ];
  }

  renderList() {
    const { items, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        pageSize={100}
        pageNumber={page}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(page) => this.onPageChange(page)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'Дата',
        value: (item) => formatDate(item.created_at),
        headClassName: 'w-120px',
      },
      {
        name: 'Время',
        value: (item) => formatTime(item.created_at),
        headClassName: 'w-120px',
      },
      {
        name: 'Операция',
        value: (item) => item.action,
        headClassName: 'w-120px',
      },
      {
        name: 'Ресурс',
        value: (item) => item.resource,
      },
      {
        name: 'Сотрудник',
        value: (item) => item.worker_name,
        headClassName: 'w-300px',
      },
    ];
  }

  async refreshData() {
    const { app } = this.props;
    const { date1, date2, workerId, resource } = this.state.currentQuery;
    const t1 = this.getUnixTimestamp(dateFns.parse(date1));
    const t2 = this.getUnixTimestamp(dateFns.addDays(dateFns.parse(date2), 1));
    const query = {
      t1,
      t2,
      ...(workerId ? { worker_id: workerId } : {}),
      ...(resource ? { resource } : {}),
    };
    try {
      const { entries } = await app.getApi().get('/journal', query);
      this.setState({ items: entries, appliedQuery: { date1, date2, workerId, resource } });
    } catch (err) {
      app.onError(err);
    }
  }

  // other helpers

  getUnixTimestamp(date) {
    return Math.round(date.valueOf() / 1000);
  }
}
