import PropTypes from 'prop-types';
import React from 'react';

import { cn } from '../../lib/utils';
import { asyncAlert } from '../../lib/utils';
import { getContractorAutocomplete, getMap, getVehicleAutocomplete } from '../../services/autocomplete.service';
import Button from '../Button';
import FormGroup from '../FormGroup';
import MClaimEdit from '../modals/MClaimEdit';
import VoidLink from '../VoidLink';
import MContractorEdit from './MContractorEdit';
import MVehicleEdit from './MVehicleEdit';

export default class MOrderClaimCreate extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['order', 'claim']).isRequired,
    orderId: PropTypes.number,
    // --
    contractor: PropTypes.object,
    vehicle: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const contractor = props.contractor;
    const vehicle = props.vehicle;
    this.state = {
      isLoading: false,
      form: {
        contractor: contractor ? this.getContractorLabel(contractor) : '',
        vehicle: vehicle ? this.getVehicleLabel(vehicle) : '',
      },
      contractors: [],
      vehicles: [],
      contractorMap: {},
      vehicleMap: {},
      contractorAutocomplete: undefined,
      vehicleAutocomplete: undefined,
      contractor,
      vehicle,
      dataVersion: 1,
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    try {
      const { items: contractors } = await app.getApi().get('/contractors');
      const { items: vehicles } = await app.getApi().get('/vehicles');
      this.setState({
        contractors,
        vehicles,
        contractorMap: getMap(contractors),
        vehicleMap: getMap(vehicles),
        contractorAutocomplete: getContractorAutocomplete(contractors),
        vehicleAutocomplete: getVehicleAutocomplete(vehicles),
      });
    } catch (err) {
      app.onError(err);
    }
  }

  // event handlers

  onFormChange(newForm) {
    const { form, vehicles, contractorMap, vehicleMap } = this.state;
    let { contractor, vehicle } = this.state;
    const isContractorChanged = newForm.contractor !== form.contractor;
    const isVehicleChanged = newForm.vehicle !== form.vehicle;
    if (isContractorChanged) {
      const match = newForm.contractor.match(/ID_(\d+)/);
      if (match) {
        const contractorId = Number(match[1]);
        contractor = contractorMap[contractorId];
      } else {
        contractor = undefined;
      }
      vehicle = undefined;
      newForm.vehicle = '';
    }
    if (isVehicleChanged) {
      const match = newForm.vehicle.match(/ID_(\d+)/);
      if (match) {
        const vehicleId = Number(match[1]);
        vehicle = vehicleMap[vehicleId];
      } else {
        vehicle = undefined;
      }
      contractor = undefined;
      newForm.contractor = '';
    }
    if (vehicle) {
      contractor = contractorMap[vehicle.owner_id];
    }
    if (contractor) {
      newForm.contractor = this.getContractorLabel(contractor);
      if (!vehicle) {
        vehicle = vehicles.find((v) => v.owner_id === contractor.id);
      }
    }
    if (vehicle) {
      if (this.getVehiclesOfContractor(contractor).length <= 1) {
        newForm.vehicle = this.getVehicleLabel(vehicle);
      }
    }
    this.setState({ form: newForm, contractor, vehicle });
  }

  onSelectVehicle(newForm) {
    const { vehicleMap } = this.state;
    const match = newForm.vehicle.match(/ID_(\d+)/);
    if (match) {
      const vehicle = vehicleMap[Number(match[1])];
      this.setState({ form: newForm, vehicle });
    }
  }

  async onNewContractor() {
    const { app } = this.props;
    const { dataVersion } = this.state;
    const contractor = await app.showModal(MContractorEdit);
    if (!contractor) {
      return;
    }
    const { items: contractors } = await app.getApi().get('/contractors');
    this.setState({
      form: {
        contractor: this.getContractorLabel(contractor),
        vehicle: '',
      },
      contractor,
      vehicle: undefined,
      contractors,
      contractorMap: getMap(contractors),
      contractorAutocomplete: getContractorAutocomplete(contractors),
      dataVersion: dataVersion + 1,
    });
  }

  async onNewVehicle() {
    const { app } = this.props;
    const { contractor, dataVersion } = this.state;
    if (!contractor) {
      asyncAlert('Сначала нужно выбрать клиента');
      return;
    }
    const vehicle = await app.showModal(MVehicleEdit, { contractor });
    if (!vehicle) {
      return;
    }
    const { items: vehicles } = await app.getApi().get('/vehicles');
    this.setState({
      form: {
        contractor: this.getContractorLabel(contractor),
        vehicle: this.getVehicleLabel(vehicle),
      },
      vehicle,
      vehicles,
      vehicleMap: getMap(vehicles),
      vehicleAutocomplete: getVehicleAutocomplete(vehicles),
      dataVersion: dataVersion + 1,
    });
  }

  async onSave() {
    const { app, close, type, orderId } = this.props;
    const { contractor, vehicle } = this.state;
    if (!type) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const url = type === 'order' ? '/orders' : '/claims';
      const { id } = await api.post(url, {
        contractor_id: contractor.id,
        vehicle_id: vehicle.id,
        order_id: orderId,
      });
      const res = await app.getApi().get(`${url}/${id}`);
      close(res[type]);
    } catch (err) {
      this.setState({ isLoading: false });
      if (err.data && (err.data.code === `order_already_exists` || err.data.code === `claim_already_exists`)) {
        if (type === 'order') {
          await this.handleOrderAlreadyExists(err.data);
        } else {
          this.handleClaimAlreadyExists(err.data);
        }
      } else {
        app.onError(err);
      }
    }
  }

  // render helpers

  renderHeader() {
    const { close, type } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">{type === 'order' ? 'Новый заказ' : 'Новая заявка'}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { form, contractor, vehicle, contractorAutocomplete, vehicleAutocomplete, dataVersion } = this.state;
    const contactorVehicles = this.getVehiclesOfContractor(contractor);
    return (
      <form className="modal-body">
        <div className="row">
          <FormGroup
            key={`contractor_${dataVersion}`}
            className="col"
            formControlClassName={contractor && 'is-valid'}
            type="text"
            name="contractor"
            label="Клиент *"
            placeholder="Поиск по ФИО, телефону"
            autocomplete={contractorAutocomplete}
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            key={`vehicle_select_${dataVersion}`}
            className={cn('col', contactorVehicles.length <= 1 && 'd-none')}
            type="select"
            options={contactorVehicles}
            name="vehicle"
            label="Автомобиль *"
            form={form}
            onChange={(newForm) => this.onSelectVehicle(newForm)}
          />
          <FormGroup
            key={`vehicle_input_${dataVersion}`}
            className={cn('col', contactorVehicles.length > 1 && 'd-none')}
            formControlClassName={vehicle && 'is-valid'}
            type="text"
            name="vehicle"
            label="Автомобиль *"
            placeholder="Поиск по гос. номеру, VIN"
            autocomplete={vehicleAutocomplete}
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <div className="col">
            <VoidLink onClick={() => this.onNewContractor()} text="Новый клиент" />
          </div>
          <div className="col">
            <VoidLink onClick={() => this.onNewVehicle()} text="Новый автомобиль" />
          </div>
        </div>
      </form>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { contractor, vehicle, isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button
          type="success"
          text="Сохранить"
          disabled={isLoading || !contractor || !vehicle}
          onClick={() => this.onSave()}
        />
      </div>
    );
  }

  // other helpers

  async handleOrderAlreadyExists(data) {
    const { app, close, type } = this.props;
    const api = app.getApi();
    this.setState({ isLoading: true });
    try {
      const msgClaimExists = 'Уже есть открытая заявка на данный автомобиль для данного клиента. Открыть заявку?';
      const msgOrderExists = 'Уже есть открытый заказ на данный автомобиль для данного клиента. Создать ещё один?';
      if (data.code === 'claim_already_exists') {
        if (window.confirm(msgClaimExists)) {
          const res = await api.get(`/claims/${data.claim_id}`);
          await app.showModal(MClaimEdit, { claim: res.claim });
          close();
        } else {
          const finalId = await this.forceCreateRequest('/orders');
          const res2 = await api.get(`/orders/${finalId}`);
          close(res2[type]);
        }
      } else {
        let finalId = data.ord_id;
        if (window.confirm(msgOrderExists)) {
          finalId = await this.forceCreateRequest('/orders');
        } else if (data.claim_id && window.confirm(msgClaimExists)) {
          const res = await api.get(`/claims/${data.claim_id}`);
          await app.showModal(MClaimEdit, { claim: res.claim });
          close();
        }
        const res2 = await api.get(`/orders/${finalId}`);
        close(res2[type]);
      }
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async handleClaimAlreadyExists(data) {
    const { app, close, type } = this.props;
    const api = app.getApi();
    this.setState({ isLoading: true });
    try {
      let finalId = data.claim_id;
      const msg = 'Уже есть открытая заявка на данный автомобиль для данного клиента. Создать ещё одну?';
      if (window.confirm(msg)) {
        finalId = await this.forceCreateRequest('/claims');
      }
      const res2 = await api.get(`/claims/${finalId}`);
      close(res2[type]);
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async forceCreateRequest(url) {
    const { app, orderId } = this.props;
    const { contractor, vehicle } = this.state;
    const api = app.getApi();
    const query = {
      contractor_id: contractor.id,
      vehicle_id: vehicle.id,
      order_id: orderId,
      force: true,
    };
    const res = await api.post(url, query);
    return res.id;
  }

  getContractorLabel(contractor) {
    return contractor.name;
  }

  getVehicleLabel(vehicle) {
    return `${vehicle.brand} ${vehicle.model} ${vehicle.year} ${vehicle.reg}`;
  }

  getVehiclesOfContractor(contractor) {
    const { vehicles } = this.state;
    if (!contractor) {
      return [];
    }
    return vehicles.reduce((memo, v) => {
      if (v.owner_id === contractor.id) {
        memo.push({ value: `ID_${v.id}`, title: `${v.brand} ${v.model} ${v.year} ${v.reg}` });
      }
      return memo;
    }, []);
  }
}
