import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { cn } from '../lib/utils';

class Sidebar extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  render() {
    const { app } = this.props;
    const user = app.getUser();
    const isVisible = user.role !== 'executor';
    // const isSchedulerEnabled = app.getConfig().scheduler.isEnabled(user);
    return (
      <div className={cn('Sidebar d-none', isVisible && 'd-lg-block')}>
        {this.renderItem('/orders', 'car')}
        {this.renderItem('/claims', 'comment')}
        {this.renderItem('/scheduler', 'calendar-alt')}
        {this.renderItem('/stock', 'box-open')}
        {this.renderItem('/reports', 'chart-bar')}
        {this.renderItem('/refs', 'book')}
        {['root', 'boss'].includes(user.role) && this.renderItem('/journal', 'user-secret')}
      </div>
    );
  }

  // render helpers

  renderItem(url, icon) {
    const { location } = this.props;
    const isActive = location.pathname.startsWith(url);
    const className = cn('Sidebar_item', isActive && 'Sidebar_item_active');
    const iconClassName = `fas fa-${icon} fa-2x`;
    return (
      <Link to={url} className={className}>
        <i className={iconClassName} />
      </Link>
    );
  }
}

export default withRouter(Sidebar);
